<template>
  <v-card
    flat
    tile
    class="grey lighten-3"
  >
    <v-card-title>
      <h2 class="headline">
        Ticket Stream
      </h2>

      <v-spacer />
    </v-card-title>

    <ag-grid-vue
      style="width: 100%; height: 87.5vh"
      class="ag-theme-alpine"
      :grid-options="gridConfig.gridOptions"
      :column-defs="gridConfig.columnDefs"
      :default-col-def="gridConfig.defaultColDef"
      :row-data="rowData"
      :pagination="false"
      row-selection="single"
      @grid-ready="onGridReady"
    />

    <ReportTicketLogModal
      v-show="selectedTicketId"
      :ticket-id="selectedTicketId"
    />
  </v-card>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from 'ag-grid-vue';
import { mapActions } from 'vuex';

import gridFormatter from '../Leads/aggrid-formatter';
import CheckColumnRenderer from '../Leads/CheckColumnRenderer.vue';
import ActionComponent from './TicketsReportComponents/ActionComponent.vue';
import ReportTicketLogModal from './TicketsReportComponents/ReportTicketLogModal.vue';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

export default {
  name: 'TicketStreamIndex',
  components: {
    CheckColumnRenderer,
    AgGridVue,
    ActionComponent,
    ReportTicketLogModal,
  },

  data() {
    return {
      gridConfig: {
        gridOptions: null,
        gridApi: null,
        columnApi: null,
        columnDefs: null,
        defaultColDef: null,
      },
      rowData: null,
      savedSearch: null,
      selectedTicketId: 0,
    };
  },

  watch: {
    rowData() {
      if (this.rowData) {
        this.gridConfig.gridApi.sizeColumnsToFit();
        this.gridConfig.gridColumnApi.autoSizeAllColumns(true);
      }
    },
  },

  beforeMount() {
    this.gridConfig.gridOptions = {};

    this.gridConfig.columnDefs = [
      {
        headerName: 'Ticket #',
        field: 'ticketNumber',
      },
      {
        headerName: 'Ticket Date',
        field: 'ticketDate',
        valueFormatter: gridFormatter.date,
      },
      {
        headerName: 'Ticket Status',
        field: 'ticketStatus',
      },
      {
        headerName: 'Workflow Name',
        field: 'workflowName',
      },
      {
        headerName: 'User Assigned',
        field: 'userAssigned',
      },
      {
        headerName: 'Completion Time',
        field: 'completionTime',
      },
      {
        headerName: 'Action',
        field: 'ticketNumber',
        cellRendererFramework: 'ActionComponent',
        onCellClicked: this.onCellClicked,
      },
    ];

    this.gridConfig.defaultColDef = {
      sortable: true,
      resizable: true,
      flex: 1,
      autoHeight: true,
    };
  },

  async mounted() {
    this.gridConfig.gridApi = this.gridConfig.gridOptions.api;
    this.gridConfig.gridColumnApi = this.gridConfig.gridOptions.columnApi;
  },

  methods: {
    ...mapActions('notification', ['setNotification']),
    async onGridReady() {
      await this.getTicketStream();

      this.gridConfig.gridApi.sizeColumnsToFit();
    },

    async onFinalResultFilterChange(value) {
      this.savedSearch = null;
      this.selectedFinalResultFilter = value;
      await this.getTicketStream();
    },

    getTicketStream() {
      this.gridConfig.gridApi.showLoadingOverlay();

      return this.$axios
        .get('api/v1/reports/ticket-streams-report')
        .then(({ status, data }) => {
          if (status === 200 && data) {
            this.rowData = data;
          } else {
            this.setNotification({
              message: 'Something went wrong, please refresh and try again later!',
              color: 'red',
            });
          }
        })
        .catch((err) => {
          this.setNotification({
            message: `Something went wrong, please refresh and try again later!, ${err.message}`,
            color: 'red',
          });
        });
    },

    onCellClicked({ value: selectedTicketId }) {
      this.selectedTicketId = Number(selectedTicketId);
    },
  },
};
</script>
<style>
.vjs-tree .vjs-value__string {
  color: seagreen;
}
</style>
