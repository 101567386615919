<template>
  <v-dialog
    v-model="dialog"
    @click:outside="dialog = !dialog"
  >
    <v-card :loading="formProcessing">
      <v-card-title>
        <span class="headline">
          Ticket Log
        </span>
      </v-card-title>

      <v-card-text>
        <v-data-table
          dense
          :headers="headers"
          :loading="loading"
          :items="items"
          :options.sync="options"
          :server-items-length="totalItems"
          class="elevation-1"
          :footer-props.sync="footerProps"
          @update:options="updateTable()"
        >
          <template v-slot:item.method="{ item }">
            {{ item.method.toUpperCase() }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              tile
              color="primary"
              small
              text
              @click="showJsonModal('JSON Payload', item.properties.request, false)"
            >
              <v-icon
                dark
                left
                class="mr-1"
              >
                mdi-eye
              </v-icon>
              PAYLOAD
            </v-btn>
            <v-btn
              tile
              color="teal"
              small
              text
              @click="showJsonModal('JSON Response', item.properties.response, true)"
            >
              <v-icon
                dark
                left
                class="mr-1"
              >
                mdi-eye
              </v-icon>
              RESPONSE
            </v-btn>
          </template>
        </v-data-table>
        <v-pagination
          v-model="pagination.page"
          :length="1"
          :total-visible="1"
          prev-icon="mdi-skip-previous"
          next-icon="mdi-skip-next"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          :disabled="formProcessing"
          @click="dialog = !dialog"
        >
          Cancel
        </v-btn>
      </v-card-actions>
      <JsonModal
        v-model="ticketLogJsonModal"
        :title="ticketLogJsonTitle"
        :json="ticketLogJson"
        @close-modal="closeJsonModal()"
        @click:outside="closeJsonModal()"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

import dataTableMixin from '@/mixins/dataTableMixin';
import JsonModal from '@/components/JsonModal.vue';

export default {
  name: 'ReportTicketLogModal',
  components: {
    JsonModal,
  },
  mixins: [
    dataTableMixin,
  ],
  props: {
    ticketId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      pagination: {
        page: 1,
      },
      ticketLogJsonModal: false,
      ticketLogJsonTitle: '',
      ticketLogJson: {},
      id: null,
      loans: [],
      formProcessing: false,
      loading: false,
      dialog: false,
      headers: [
        { text: 'Context', value: 'properties.context.name' },
        { text: 'Description', value: 'description', width: 120 },
        { text: 'Endpoint', value: 'properties.endpoint', width: 120 },
        { text: 'Method', value: 'properties.method' },
        { text: 'Response', value: 'properties.status' },
        { text: 'Time', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  watch: {
    ticketId(value) {
      if (value) {
        this.id = this.ticketId;
        this.dialog = true;
      }
    },
  },
  methods: {
    ...mapActions('notification', ['setNotification']),
    closeJsonModal() {
      this.ticketLogJsonModal = false;
      this.$nextTick(() => {
        this.ticketLogJson = {};
      });
    },
    showJsonModal(title, json, convert) {
      try {
        this.ticketLogJson = convert ? JSON.parse(json) : json;
        this.ticketLogJsonTitle = title;
        this.ticketLogJsonModal = true;
      } catch (e) {
        this.ticketLogJson = {};
      }
    },
    updateTable() {
      this.loading = true;

      return this.$axios
        .get('api/v1/support/ticket/activity', {
          params: {
            ticketId: this.ticketId,
            perPage: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
          },
        })
        .then((res) => {
          this.loading = false;
          this.items = res.data.data;
          this.totalItems = res.data.total;
        });
    },
  },
};
</script>

<style scoped>

</style>
