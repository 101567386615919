<template>
  <v-btn
    block
    text
  >
    <v-icon class="mr-1">
      mdi-eye
    </v-icon>
    View Logs
  </v-btn>
</template>

<script>
export default {
  // this.params.value
};
</script>
